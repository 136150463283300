import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notavailable',
  templateUrl: './notavailable.component.html',
  styleUrls: ['./notavailable.component.css']
})
export class NotavailableComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    if(!environment.isComputeOperaterEnable){
      this.router.navigate(['/compute/environment']);
    }
    else{
      this.router.navigate(['/login']);
    }

  }

}
