import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Compute Operator';

  public clickedEvent: Event;

  ngOnInit() {
    if(!environment.isComputeOperaterEnable) {
      this.router.navigate(['/compute/environment']);
    }
  }
  constructor(private router: Router){
    
  }

  childEventClicked(event: Event) {
    this.clickedEvent = event;
  }
}
