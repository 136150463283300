import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TransmissionType } from 'src/app/shared/models/transmission-type';

@Injectable({
  providedIn: 'root'
})
export class ComputeOperatorService {
  constructor(private http: HttpClient) { }

  saveFile(data: any): Observable<any> {
    return this.http.post(environment.endPoint + '/api/ComputeOperator/savefile',
      data);
  }

  getFiles(): Observable<any> {
    return this.http.get(environment.endPoint + '/api/ComputeOperator/getfiles');
  }

  deleteFiles(): Observable<any> {
    return this.http.delete(environment.endPoint + '/api/ComputeOperator/deletefiles');
  }

  triggerIngestion(data: any): Observable<any> {
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.post(environment.ingestionEndPoint + '/api/Ingestion/Ingestions',
      data, options);
  }

  sendCoreSurvey(data: any, transmissionType: TransmissionType): Observable<any> {
    const transmission = transmissionType === TransmissionType.Invite ? 'Transmissions' : 'Reminders';
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.post(environment.broadcasterEndPoint + `/api/Broadcaster/${transmission}`,
      data, options);
  }
}
