// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  endPoint: '',
  ingestionEndPoint: '/ingestion',
  broadcasterEndPoint: '/broadcaster',
  isComputeOperaterEnable : true,
  coreSurveyClientId: '5af3178c141292374cbf363e',
  // coreSurveyClientId: '5d306117d5f4904dd059d25f',
  executionEnvironments : [
    {
      name : 'QA',
      ftp : {
        host : '192.168.190.82',
        username : 'ftpqa',
        password : 'Password12345',
        basefolder : 'TestUpload'
      }
    },
    {
      name : 'UAT',
      ftp : {
        host : '192.168.190.82',
        username : 'ftpqa',
        password : 'Password12345',
        basefolder : 'TestUpload'
      }
    },
    {
      name : 'Staging',
      ftp : {
        host : '192.168.190.82',
        username : 'ftpqa',
        password : 'Password12345',
        basefolder : 'TestUpload'
      },
    },
    {
      name : 'Prod',
      ftp : {
        host : '192.168.190.82',
        username : 'ftpqa',
        password : 'Password12345',
        basefolder : 'TestUpload'
      }
    }
    // {
    //   name : 'Dev',
    //   ftp : {
    //     host : '172.24.80.134',
    //     username : 'devteam',
    //     password : '5af3178c141292374cbf363e',
    //     basefolder : 'TestUpload'
    //   }
    // },
    // {
    //   name : 'Prod',
    //   ftp : {
    //     host : '172.24.80.135',
    //     username : 'devteam',
    //     password : '5af3178c141292374cbf363e',
    //     basefolder : 'TestUpload'
    //   }
    // }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
