import { Component, OnInit, ViewChild } from '@angular/core';
import { ComputeOperatorService } from './compute-operator.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { WizardComponent } from 'angular-archwizard';
import { TransmissionType } from 'src/app/shared/models/transmission-type';

@Component({
  selector: 'app-compute-operator',
  templateUrl: './compute-operator.component.html',
  styleUrls: ['./compute-operator.component.css'],
  providers: [DatePipe]
})
export class ComputeOperatorComponent implements OnInit {
  myDate = '';
  constructor(private service: ComputeOperatorService, private spinner: NgxSpinnerService, private router: Router,
    // tslint:disable-next-line:align
    private datePipe: DatePipe) { }
  private ftpHost = '172.24.80.133';
  private ftpUserName = 'devteam';
  private ftpPassword = 'UuRu6YjwHdT5JqeSasJ5';
  private folder = 'TestUpload';
  private computeRecipients = 'aurangzeb.khan@ibex.co,ahmer.sohail@ibex.co';
  private step = '1';
  public progress: string;
  isReminderValid = false;
  public filesToProcess: [] = [];
  public execEnvironments = environment.executionEnvironments.map(e => e.name);
  public Providers = ['Ibex', 'MBC'];
  public selectedProvider = '';
  public selectedFtpCreds;
  public selectedEnvironment = '';
  public IsTestRecord = false;
  public basefolder = '';

  @ViewChild(WizardComponent)
  public wizard: WizardComponent;

  ngOnInit() {
    this.myDate = this.datePipe.transform(new Date(), 'yyyyMMdd');
    this.spinner.show();
    this.getFilesToUpload();
    if (localStorage.getItem('ftpData')) {
      const ftpData = JSON.parse(localStorage.getItem('ftpData'));
      this.ftpHost = ftpData.host;
      this.ftpUserName = ftpData.userName;
      this.ftpPassword = ftpData.password;
      this.folder = this.folder;
    }
  }

  upload(file, wizard: any, type: string) {
    const files = file.files;
    console.log(this.selectedFtpCreds);
    if (this.selectedProvider === '') {
      this.progress = 'Please select Provider.';
      return;
    }

    if (files.length === 0 || this.ftpHost === '' || this.ftpUserName === ''
      || this.ftpPassword === '' || this.folder === '') {
      this.progress = 'Please select file.';
      return;
    }
    this.progress = 'Uploading';
    const formData = new FormData();
    let fileName = '';
    for (const fileA of files) {
      fileName = fileA.name;
      if (this.selectedProvider.toLowerCase() === 'ibex') {
        if (!fileA.name.toLowerCase().includes('ibex_invite')) {
          this.progress = 'Please select correct file for Ibex Provider, file name must contain {ibex_invite}';
          return;
        }
      } else if (this.selectedProvider.toLowerCase() === 'mbc') {
        if (!fileA.name.toLowerCase().includes('mb_ca_invite')) {
          this.progress = 'Please select correct file for MBC provider, file name must contain {mb_ca_invite}';
          return;
        }
      }
      formData.append(fileA.name, fileA);
    }

    if (!fileName.includes(type)) {
      this.progress = `Please upload ${type} type file.`;
      return;
    }

    formData.append('Host', this.selectedFtpCreds.host);
    formData.append('UserName', this.selectedFtpCreds.username);
    formData.append('Password', this.selectedFtpCreds.password);
    formData.append('Folder', this.selectedFtpCreds.basefolder + '/' + this.selectedEnvironment + '/' + this.folder);
    formData.append('Provider', this.selectedProvider.toLowerCase());

    this.spinner.show();
    this.service.saveFile(formData)
      .subscribe(result => {
        file.value = '';
        this.step = '2';
        this.progress = 'Upload Complete';
        this.getFilesToUpload();
        wizard.goToNextStep();
      }, err => {
        this.progress = err.error;
        file.value = '';
        this.spinner.hide();
      });
  }

  getFilesToUpload() {
    this.service.getFiles()
      .subscribe(a => {
        this.filesToProcess = a;
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
      });
  }

  deleteFiles() {
    this.service.deleteFiles()
      .subscribe(a => {
        this.getFilesToUpload();
      });
  }

  triggerIngestion(wizard: any) {


    this.spinner.show();
    debugger;
    const data = {
      ServiceInfo: 'John',
      // tslint:disable-next-line:max-line-length
      ParamBody: '{"Body": {"ClientId" : "' + environment.coreSurveyClientId + '","Host" : "' + this.selectedFtpCreds.host + '", "UserName" : "' + this.selectedFtpCreds.username + '", "Password" : "' + this.selectedFtpCreds.password + '","NotificationRecipients" : "' + this.computeRecipients + '","RemotePath" : "' + this.selectedFtpCreds.basefolder + '/' + this.selectedEnvironment + '/' + this.folder + '", "FileNamePattern": "mb_ca_invite_ProgramName_yyyyMMdd.Extension","AllowedExtensions": "*.gpg,*.pgp", "IsTestRecord":"' + this.IsTestRecord + '", "environment":"' + this.selectedEnvironment + '"} }'
    };
    this.service.triggerIngestion(data)
      .subscribe(result => {
        // if (result.length === 2 && result[1] && result[1] === 'Success') {
        //   // this.deleteFiles();
        //   this.progress = 'Ingestion: Success';
        //   this.step = '3';
        //   wizard.goToNextStep();
        // } else if (result.length === 1 && result[0] && result[0].EventMsg !== null) {
        //   this.progress = 'Ingestion: ' + result[0].EventMsg;
        // } else {
        //   this.progress = 'Ingestion: Something went wrong...';
        // }
        this.progress = 'Ingestion: Initiated';
        wizard.goToNextStep();
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
      });
  }

  sendCoreSurvey(wizard: any, transmissionType: TransmissionType) {
    this.spinner.show();
    const cliendId = environment.coreSurveyClientId;
    const data = {
      ParamBody: `{"clientId": "${cliendId}","environment": "${this.selectedEnvironment}"}`,
      TimeZone: 'Pacific Standard Time'
    };
    this.service.sendCoreSurvey(data, transmissionType)
      .subscribe(result => {
        this.spinner.hide();
        this.step = '1';
        if (transmissionType === TransmissionType.Reminder) {
          wizard.goToStep(0);
          this.isReminderValid = false;
          this.progress = 'Survey: Reminder Process initiated...';
        } else {
          this.progress = 'Survey: Sending Process initiated..., please wait 5 seconds to initiate reminder';
          setTimeout(() => this.isReminderValid = true, 5000);
        }
      }, err => {
        this.spinner.hide();
        this.progress = 'Survey: Something went wrong...';
        this.isReminderValid = false;
      });
  }

  logout() {
    localStorage.removeItem('userData');
    this.router.navigate([`/login`]);
  }

  downloadSales() {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = '../../../assets/name_sales_yyyymmdd.xlsx';
    link.download = 'mb_ca_inviteNAME_sales_' + this.myDate;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  downloadService() {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = '../../../assets/name_service_yyyymmdd.xlsx';
    link.download = 'mb_ca_inviteNAME_service_' + this.myDate;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  resetWizard(wizard: any) {
    wizard.reset();
    this.isReminderValid = false;
    this.IsTestRecord = false;
    this.progress = '';
    this.basefolder = '';
  }

  continueWithFTP(wizard: any) {
    if (this.selectedEnvironment === '') {
          this.progress = 'Please select environment for file execution';
          return;
    } else {
      const ftpData = this.GetFTPCreds();
      localStorage.setItem('ftpData', JSON.stringify(ftpData));
      wizard.goToNextStep();
    }
  }

  // continueWithEnvironment(wizard: any) {
  //   if (this.selectedEnvironment === "") {
  //     this.progress = 'Please select environment for file execution';
  //     return;
  //   }
  //   wizard.goToNextStep();
  // }

  changeProvider(provider) {
    this.selectedProvider = provider.target.value;
  }

  changeEnvironment(env) {
    this.selectedEnvironment = env.target.value;
    environment.executionEnvironments.forEach(x => {
      if (x.name === this.selectedEnvironment) {
        this.basefolder = x.ftp.basefolder + '/' + this.selectedEnvironment + '/';
      }
    });
    return this.basefolder;
  }

  GetFTPCreds() {
    environment.executionEnvironments.forEach(x => {
      if (x.name === this.selectedEnvironment) {
        this.selectedFtpCreds =  {
          host: x.ftp.host,
          username: x.ftp.username,
          password: x.ftp.password,
          basefolder: x.ftp.basefolder
        };
      }
    });
    return this.selectedFtpCreds;
  }

}
