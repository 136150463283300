import { Injectable } from '@angular/core';
import { DateTimeHelper } from 'src/app/shared/helpers/dateTimeHelper'
import { SelectItem } from 'primeng/components/common/selectitem';

@Injectable({
  providedIn: 'root'
})
export class CronCalendarService {

  // private minsDropDownModel: DropDownModel[] = [];
  // private hoursDropDownModel: DropDownModel[] = [];
  // private daysDropDownModel: DropDownModel[] = [];
  // private timeZoneDropDownModel: DropDownModel[] = [];

  private minsDropDownModel: SelectItem[] = [];
  private hoursDropDownModel: SelectItem[] = [];
  private daysDropDownModel: SelectItem[] = [];
  private timeZoneDropDownModel: SelectItem[] = [];

  constructor() { }

  getMintues(): SelectItem[] {

    for (var minutes = 1; minutes <= 60; minutes++) {
      this.minsDropDownModel.push({ label: minutes.toString(), value: minutes.toString() });
    }

    return this.minsDropDownModel;
  }

  getHours(): SelectItem[] {

    for (var hours = 1; hours <= 24; hours++) {
      this.hoursDropDownModel.push({ label: hours.toString(), value: hours.toString() });
    }

    return this.hoursDropDownModel;
  }

  getDays(): SelectItem[] {

    var daysInCurrentMonth = DateTimeHelper.daysInThisMonth();

    for (var daysInMonth = 1; daysInMonth <= daysInCurrentMonth; daysInMonth++) {
      this.daysDropDownModel.push({ label: daysInMonth.toString(), value: daysInMonth.toString() });
    }

    return this.daysDropDownModel;
  }

  getTimeZones(): SelectItem[] {

    this.timeZoneDropDownModel = [
      //{ name: 'Select Time Zone', code: null },
      // { name: 'Pacific Time (Canada)', code: 'Pacific Time (US & Canada)' },
      // { name: 'Mountain Time Day Light Saving (Canada)', code: 'Mountain Time (US & Canada)' },
      // { name: 'Mountain Time (Canada)', code: 'Mountain Time (US & Canada)' },
      // { name: 'Mountain Standard Time (Canada)', code: 'Mountain Time (US & Canada)' },
      // { name: 'Eastern Time (Canada)', code: 'Eastern Time (US & Canada)' },
      // { name: 'Eastern Standard Time (Canada)', code: 'Eastern Time (US & Canada)' }
      
      { label: 'Pacific Time (Canada)', value: 'Pacific Standard Time' },
      { label: 'Central Time (Canada)', value: 'Central Standard Time' },
      { label: 'Mountain Time (Canada)', value: 'Mountain Standard Time' },
      { label: 'Atlantic Time (Canada)', value: 'Atlantic Standard Time' },
      { label: 'Eastern Time (Canada)', value: 'Eastern Standard Time' },
      { label: 'Newfoundland Time (Canada)', value: 'Newfoundland Standard Time' },
      {label: 'West Asia Standard Time (Asia)', value: 'West Asia Standard Time'}
    ];
    return this.timeZoneDropDownModel;
  }

}


