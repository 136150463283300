import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
//Ref: https://stackoverflow.com/questions/43940351/how-do-i-share-data-between-sibling-components-in-angular
export class DatashareService {

  constructor() { }

  private data:any = undefined;

    setData(data:any){
        this.data = data;
    }

    getData():any{
        return this.data;
    }
}
