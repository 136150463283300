import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username = '';
  password = '';
  message = '';
  constructor(private router: Router, private service: LoginService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    if(!environment.isComputeOperaterEnable) {
      this.router.navigate(['/compute/environment']);
    }
  }

  login() {
    if (this.username !== '' && this.password !== '') {
      // const userData = {
      //   data : btoa(this.username + ':' + this.password)
      // };
      const encodedString = btoa(this.username + ':' + this.password);
      const data = new FormData();
      data.append('data', encodedString);
      // const userData = btoa(this.username + ':' + this.password);
      this.spinner.show();
      this.service.authenticateUser(data).subscribe(res => {
        this.spinner.hide();
        localStorage.setItem('userData', encodedString);
        this.router.navigate([`/compute`]);
      }, err => {
        this.username = '';
        this.password = '';
        this.spinner.hide();
        this.message = 'Wrong credentials...';
      });
    }
  }
}
