import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  authenticateUser(data: any): Observable<any> {
    // const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.post(environment.endPoint + '/api/ComputeOperator/authenticateUser',
      data);
  }
}
